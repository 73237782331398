import React, { useRef, useEffect } from "react"
import ReactHtmlParser from 'react-html-parser'

import { graphql, Link } from "gatsby"
import gsap, { Power1 } from 'gsap';
import { useMediaQuery } from 'react-responsive'

import Layout from "../parts/layout"
import Seo from "../parts/seo"

import Blob from "../images/inline/blob.svg"

import Toaster from "../images/inline/toaster.svg"
import Phone from "../images/inline/phone.svg"
import PhoneS1 from "../images/inline/phone-s-1.svg"
import PhoneS2 from "../images/inline/phone-s-2.svg"
import PhoneS3 from "../images/inline/phone-s-3.svg"
import Stack from "../images/inline/stack.svg"



const IndexPage = ({ data, location }) => {
  const isSmallScreen = useMediaQuery({ query: '(max-device-width: 480px)' })

  //let toaster = useRef(null)
  let phone = useRef(null)
  let phoneTrigger = useRef(null)
  let stack = useRef(null)
  let stackTrigger = useRef(null)

  useEffect(()=> {
    // TOASTER ANIMATION
    // const tlToaster = gsap.timeline().repeat(-1)
    
    // tlToaster.to(toaster.querySelectorAll("#toaster"), {
    //   motionPath: {
    //     path: "M3.05,15.07C14.5-13.25,24.6,4.94,52.92,16.39S107.48,20.74,96,49.06,70.7,98.47,42.39,87-8.4,43.38,3.05,15.07Z",
    //     curviness: 3
    //   },
    //   duration: 42,
    //   ease: "rough"
    // })

    // PHONE ANIMATION
    if(!isSmallScreen) {
      const phoneTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: phoneTrigger,
          start: "-66px top",
          end: "bottom 87%",
          toggleActions: "play pause reverse reset",
          scrub: 0.25,
          pin: phone,
        },
        ease: Power1.easeInOut
      })

      const phoneKeyframes = [0.8, 2]

      // move layers vertically
      phoneTimeline.to(phone.querySelectorAll("#buttons"), { y: -300 }, phoneKeyframes[0])
      phoneTimeline.to(phone.querySelectorAll("#screen"),  { y: -200 }, phoneKeyframes[0])
      phoneTimeline.to(phone.querySelectorAll("#front"),   { y: -100 }, phoneKeyframes[0])
      phoneTimeline.to(phone.querySelectorAll("#capital"), { y: 150 },  phoneKeyframes[0])
      phoneTimeline.to(phone.querySelectorAll("#society"), { y: 400 },  phoneKeyframes[0])
      // unhide hidden layers
      phoneTimeline.to(phone.querySelectorAll("#digits-bg"),  { opacity: 1 }, phoneKeyframes[0])
      phoneTimeline.to(phone.querySelectorAll("#capital-bg"), { opacity: 1 }, phoneKeyframes[0])
      phoneTimeline.to(phone.querySelectorAll("#society-bg"), { opacity: 1 }, phoneKeyframes[0])
      // reveal content of hidden layers
      phoneTimeline.to(phone.querySelectorAll("#digits-content"),  { opacity: 1 }, phoneKeyframes[1])
      phoneTimeline.to(phone.querySelectorAll("#capital-content"), { opacity: 1 }, phoneKeyframes[1])
      phoneTimeline.to(phone.querySelectorAll("#society-content"), { opacity: 1 }, phoneKeyframes[1])


      // STACK ANIMATION
      const stackTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: stackTrigger,
          start: "-66px top",
          end: "bottom 83%",
          toggleActions: "play pause reverse reset",
          scrub: 0.25,
          pin: stack,
        },
        ease: Power1.easeInOut
      })

      const stackKeyframes = [0.5, 1.5, 2.5]

      stackTimeline.to(stack.querySelectorAll("#empty-stack"),   { opacity: 0 }, stackKeyframes[0])
      stackTimeline.to(stack.querySelectorAll("#private-stack"), { opacity: 1 }, stackKeyframes[0])
      //stackTimeline.to(stack.querySelectorAll("#private-stack"), { opacity: 1 }, 2)

      stackTimeline.to(stack.querySelectorAll("#private-stack"), { opacity: 0 }, stackKeyframes[1])
      stackTimeline.to(stack.querySelectorAll("#state-stack"),   { opacity: 1 }, stackKeyframes[1])

      stackTimeline.to(stack.querySelectorAll("#state-stack"),   { opacity: 0 }, stackKeyframes[2])
      stackTimeline.to(stack.querySelectorAll("#public-stack"),  { opacity: 1 }, stackKeyframes[2])
    }
  })

  return (
    <Layout id="front-page" image={ Blob }>
      <Seo title="" />

      <section id="front-introduction">
        <div className="row">
          <div className="columns-2 s-column-reverse">
            <div className="column v-center">{ ReactHtmlParser(data.strapiHome.introduction) }</div>
            <div className="column v-center">
              {/* <div ref={el => toaster = el}> */}
                <Toaster id="toaster" />
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>

      <section id="front-layer-explanation" style={{background: "#F8F8F8"}} ref={el => phoneTrigger = el}>
        <div className="row">
          <div className="columns-2">
            { !isSmallScreen && 
            <div className="column" ref={el => phone = el}>
              <Phone id="phone" className="visual-part" />
            </div>
            }
            <div className="column">
              <div className="text-part v-center">
                { isSmallScreen && <PhoneS1 /> }
                { ReactHtmlParser(data.strapiHome.layer_explanation_1) }
              </div>
              <div className="text-part v-center">
                { isSmallScreen && <PhoneS2 /> }
                { ReactHtmlParser(data.strapiHome.layer_explanation_2) }
              </div>
              <div className="text-part v-center">
                { isSmallScreen && <PhoneS3 /> }
                { ReactHtmlParser(data.strapiHome.layer_explanation_3) }
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="front-stacks" ref={el => stackTrigger = el}>
        <div className="row">
          <div className="columns-2">
            <div className="column">
              <div className="text-part v-center">{ ReactHtmlParser(data.strapiHome.layer_explanation_4) }</div>
              <div className="text-part v-center">{ ReactHtmlParser(data.strapiHome.three_stacks_1) }</div>
              <div className="text-part v-center">{ ReactHtmlParser(data.strapiHome.three_stacks_2) }</div>
              <div className="text-part v-center">{ ReactHtmlParser(data.strapiHome.three_stacks_3) }</div>
            </div>
            { !isSmallScreen && 
              <div className="column" ref={el => stack = el}>
                <Stack id="stack" className="visual-part" />
              </div>
            } 
          </div>
        </div>
      </section>

      <section id="next-actions" style={{ minHeight: "auto" }}>
        <div className="row center">
          <h2>Explore the layers of the stack</h2>
          <Link className="button" to="/layers/">Start exploring</Link>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage


export const indexQuery = graphql`
  query IndexQuery {
    strapiHome {
      introduction
      layer_explanation_1
      layer_explanation_2
      layer_explanation_3
      layer_explanation_4
      three_stacks_intro
      three_stacks_1
      three_stacks_2
      three_stacks_3
    }
  }
`